<template>
  <div  class="px-15px px-lg-25px" v-if="detailData !== undefined">
    <div class="card">
      <div class="card-header">
        <h1 class="h2 fs-16 mb-0">{{ $t('ding-dan-xiang-qing') }}</h1>
      </div>
      <div class="card-body">
        <div class="row gutters-3">
          <div class="col text-md-left text-center">
          </div>
          <div class="col-md-2 d-flex flex-nowrap justify-content-end align-items-end ml-auto" v-if=" detailData.Status < 2 ">
            <button id="free_up_btn" type="button" class="btn btn-primary confirm-alert" @click="cancelOrder">{{ $t('qu-xiao-ding-dan') }}</button>
          </div>
          <div class="col-md-3 ml-auto">
              <label for="update_delivery_status">{{$t('you-ji-zhuang-tai')}}</label>
              <input type="text" class="form-control" readonly v-model="detailData.statusName">
          </div>
        </div>
         
        <div class="mb-3">
          
        </div>
        <div class="row gutters-5">
          <div class="col text-md-left text-center">
            <address>
              <div>{{$t('quan-ming')}}：{{detailData.Address.FullName}}</div>
              <div>{{$t('dian-hua')}}：{{detailData.Address.Telephone}}</div>
              <div>{{$t('cheng-shi')}}：{{detailData.Address.City}}</div>
              <div>{{$t('di-zhi')}}：{{detailData.Address.Address}}</div>
            </address>
          </div>
          <div class="col-md-5 ml-auto">
            <table>
              <tbody>
                <tr>
                  <td class="text-main text-bold">{{ $t('ding-dan-hao-0') }}</td>
                  <td class="text-info text-bold text-right"> {{ detailData.OrderNo }}</td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('ding-dan-zhuang-tai') }}</td>
                  <td class="text-right">
                    <span  class="badge badge-inline badge-info" v-if="detailData.Status == 0">{{ $t('deng-dai-mai-jia-fa-huo') }}</span>
                    <span  class="badge badge-inline badge-info" v-if="detailData.Status == 1">{{ $t('yi-ti-huo') }}</span>
                    <span  class="badge badge-inline badge-info" v-if="detailData.Status == 2">{{ $t('zai-tu-zhong') }}</span>
                    <span  class="badge badge-inline badge-success" v-if="detailData.Status == 3">{{ $t('yi-qian-shou') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 4">{{ $t('Illegal order') }}</span>
                    <span  class="badge badge-inline badge-danger" v-if="detailData.Status == 5">{{ $t('yi-qu-xiao') }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('ding-gou-ri-qi-0') }}</td>
                  <td class="text-right">{{detailData.CreatedAt}}</td>
                </tr>
                <tr>
                  <td class="text-main text-bold">{{ $t('zong-jin-e') }}</td>
                  <td class="text-right">
                    ${{ detailData.TotalAmount }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <hr class="new-section-sm bord-no">
        <div class="row">
          <div class="col-lg-12 table-responsive">
            <table class="table-bordered  invoice-summary table">
              <thead>
                <tr class="bg-trans-dark">
                  <th data-breakpoints="lg" class="min-col">#</th>
                  <th width="10%">{{ $t('zhao-pian') }}</th>
                  <th class="text-uppercase">{{ $t('shang-pin-ming-cheng-0') }}</th>
                  <th data-breakpoints="lg" class="text-uppercase">{{ $t('jiao-huo-lei-xing') }}</th>
                  <th data-breakpoints="lg" class="text-uppercase">{{ $t('bei-zhu') }}</th>
                  <th data-breakpoints="lg" class="min-col text-uppercase text-center">
                    {{ $t('shu-liang-0') }} </th>
                  <th data-breakpoints="lg" class="min-col text-uppercase text-center">
                    {{ $t('jia-ge') }} </th>
                  <th data-breakpoints="lg" class="min-col text-uppercase text-right">
                    {{ $t('zong-ji-0') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in detailData.Products" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                      <img height="50" :src="item.Product.ImgUrl">
                  </td>
                  <td>
                     {{item.Product ? item.Product.GoodsName : ''}}
                  </td>
                  <td>
                    {{ $t('song-huo-shang-men') }} </td>
                  <td class="text-center">
                    {{item.Remark}}
                  </td>
                  <td class="text-center">{{ item.Quantity}}</td>
                  <td class="text-center">
                    ${{item.Amount}}
                  </td>
                  <td class="text-center">${{ parseFloat(detailData.TotalAmount).toFixed(2)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
<!--        回复输入框-->
        <div class="row" >
          <div class="card-body">
            <form >
              <div class="form-group">
                <textarea class="form-control mb-4" name="reply" rows="8" v-model="form.content"></textarea>
              </div>
              <div class="form-group mb-0 text-right">
                <button type="button" class="btn btn-sm btn-primary" @click="add">{{ $t('zi-xun') }}</button>
              </div>
            </form>
          </div>
        </div>
        <div class="clearfix float-right" v-if="false">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <strong class="text-muted">{{ $t('ti-huo-jia-ge') }}</strong>
                </td>
                <td>
                  ${{ parseFloat(detailData.PickupAmount).toFixed(2)}}
                </td>
              </tr>
              <tr>
                <td>
                    <strong class="text-muted">{{ $t('li-run') }}</strong>
                </td>
                <td>
                  ${{ parseFloat(detailData.Profit).toFixed(2)}}
                </td>
              </tr>
              <tr>
                <td>
                    <strong class="text-muted">{{ $t('xiao-ji-2') }}</strong>
                </td>
                <td>
                  ${{ parseFloat(detailData.TotalAmount).toFixed(2)}}
                </td>
                </tr>
                <tr>
                  <td>
                      <strong class="text-muted">{{ $t('shui-1') }}</strong>
                  </td>
                  <td>
                      $0.00
                  </td>
              </tr>
              <tr>
                <td>
                  <strong class="text-muted">{{ $t('yun-fei') }}</strong>
                </td>
                <td>
                  $0.00
                </td>
              </tr>
              <tr>
                <td>
                  <strong class="text-muted">{{ $t('you-hui-quan-0') }}</strong>
                </td>
                <td>
                  $0.00
                </td>
              </tr>
              <tr>
                <td>
                    <strong class="text-muted">{{ $t('zong-ji-1') }}</strong>
                </td>
                <td class="text-muted h5">
                  ${{ parseFloat(detailData.TotalAmount).toFixed(2)}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="no-print text-right">
            <a  type="button" class="btn btn-icon btn-light">
              <i class="las la-print"></i>
            </a>
          </div> -->
        </div>


        <div class="row gutters-5" id="wuliu" v-if="false">
          <div class="col-md-12 ml-auto" style="margin-top:10px;">
            <label for="update_tracking_code">{{ $t('orderDetail-1') }}</label>
            <div style="border:1px solid #f2f3f8; border-radius:10px;;padding:10px;margin-bottom:10px;" id="expessdiv">
              <form id="form1">
                <div class="dv">
                  {{ $t('orderDetail-2') }}：
                  <span>{{logistics.Company}}</span>
                </div>
                <div class="dv" v-if="logisticsList.length > 0">
                  <br><br>
                  {{ $t('orderDetail-3') }}：<br><br>
                  <div class="exp" v-for="(item, i) in logisticsList" :key="i">
                    <div>{{ $t('orderDetail-4') }}：{{item.Description}}</div>
                    <div>{{ $t('orderDetail-5') }}：{{item.CreatedAt}}</div>
                    <br>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Popup v-model="showModal">
      <div class="popup-box " >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('que-ren-fu-kuan') }}</h5>
              <button type="button" class="close" @click="cancel"></button>
            </div>
            <form class="" >
              <div class="modal-body gry-bg px-3 pt-3">
                
                <div class="row">
                  <div class="col-md-3">
                    <label>{{ $t('zi-jin-mi-ma') }} <span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-9">
                     <input type="password"  class="form-control mb-3" v-model="password" :placeholder="$t('jin-e')" required>
                  </div>
                </div>
                
                <div class="form-group text-right">
                  <button type="button" class="btn btn-sm btn-primary transition-3d-hover mr-1" @click="doPay">{{ $t('que-ding') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import { Dialog } from 'vant';
// import { shopOrderList, orderPay } from '@/api/seller'
import { resetPrice } from '@/utils/common'
import { Popup } from 'vant'
import {orderList,orderCancel} from "@/api/user";
import {dialogueAdd} from "@/api/index";
export default {
  components: {
    pageBar,
    Popup,
    Dialog
  },
  data() {
    return {
      showModal: false,
      password: '',
      detailData: undefined,
      // 确定是添加还是回复
      isAdd: false,
      form:{
        content:"",
      },
      statusList: [
        {
          value: 1,
          label: this.$t('wei-fa-huo')
        },
        {
          value: 2,
          label: this.$t('fa-huo-zhong')
        },
        {
          value: 3,
          label: this.$t('yi-shou-huo')
        },
      ],
      dataId: '',
      logistics: {},
      logisticsList: []
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    // let detailData = JSON.parse(sessionStorage.getItem('orderData'))
    // detailData.Products = detailData.Products.map(v => {
    //   v.Product = JSON.parse(v.Product)
    //   return v
    // })
    // detailData.payStatusName = this.getPayStatusName(detailData.PayStatus)
    // detailData.statusName = this.getStatusName(detailData.Status)
    // this.detailData = detailData
    this.init()
  },
  methods: {
    resetPrice,
    submit(){

    },
    //  取消订单
    cancelOrder() {
      Dialog.confirm({
        // title: this.$t('que-ren-qu-xiao-ding-dan'),
        message: this.$t('que-ren-qu-xiao-ding-dan'),
        confirmButtonText: this.$t('que-ding'),
        cancelButtonText: this.$t('bao-liu-ding-dan')
      }).then(() => {
        let form = new FormData()
        form.append('ID', this.detailData.ID)
        orderCancel(form).then(res => {
          if (res.code === 0) {
            this.$toast.success(this.$t('qu-xiao-cheng-gong'))
            this.init()
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }).catch(() => {
        // on cancel
      })
    },
    // 对话添加
    add() {
      // 给卖家留言
      // this.form.question = ''
      if (!this.form.content) {
        this.errorList = []
        this.$toast(this.$t('Please-enter-consultation'))
        return
      }
      this.errorList = []
      var f = new FormData()
      f.append('Content', this.form.content)
      f.append('ProductID', this.detailData.Products[0].Product.ID)
      dialogueAdd(f).then(res => {
        if (res.code === 0) {
          this.form.question = ''
          this.$toast(this.$t('ti-jiao-cheng-gong'))
        } else {
          this.$toast(res.msg)
        }
      })
    },
    // 对话回复
    init() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 10)
      form.append('MerchantPayStatus', '')
      form.append('ID', this.dataId)
      orderList(form).then(res => {
        let data = res.data.Items
        if (data.length > 0) {
          this.detailData = data[0]
          this.detailData.Address = JSON.parse(this.detailData.Address)
          this.detailData.payStatusName = this.getPayStatusName(this.detailData.PayStatus)
          this.detailData.statusName = this.getStatusName(this.detailData.Status)
          this.detailData.Products.forEach(v => {
            v.Product = JSON.parse(v.Product)
          })
          this.form.content = this.$t("ding-dan-hao") + ":" + this.detailData.OrderNo
          //物流信息
          let logisticId = JSON.parse(this.detailData.ShowRoute)
          this.logistics = this.detailData.Logistics
          this.logisticsList = this.detailData.Logistics ? this.detailData.Logistics.LogisticsRoute.filter(v => {
            if (logisticId instanceof Array) {
              return logisticId.indexOf(v.ID) > -1
            } else {
              return logisticId[v.ID]
            }
          }) : []
        }
      })
    },
    toPay() {
      this.showModal = true
    },
    doPay() {
      let form = new FormData()
      form.append('ID', this.detailData.ID)
      form.append('Password', this.password)
      orderPay(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('fu-kuan-cheng-gong'))
          this.init()
          this.showModal = false
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    getPayStatusName(status) {
      return status == 1 ?  this.$t('yi-zhi-fu') : this.$t('wei-zhi-fu')
    },
    getStatusName(status) {
      let name = ''
      switch(status + '') {
        case '0': name = this.$t('deng-dai-mai-jia-fa-huo'); break;
        case '1': name = this.$t('yi-ti-huo'); break;
        case '2': name = this.$t('zai-tu-zhong'); break;
        case '3': name = this.$t('yi-qian-shou'); break;
        case '4': name = this.$t('Illegal order'); break;
        case '5': name = this.$t('yi-qu-xiao'); break;
      }
      return name
    },
    cancel() {
      this.showModal = false
    }
  }
}
</script>